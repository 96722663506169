import { Link, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { FLICKR_URL, IG_URL } from '../common/constants'

const EventsFooter = ({ pageId, title, description, link: { href, label } }) => {
  const clickableDescription = description
    .replace('Flickr', `<a target='_blank' class="link" rel='noreferrer' href=${FLICKR_URL}>Flickr</a>`)
    .replace('Instagram', `<a target='_blank' class="link" rel='noreferrer' href=${IG_URL}>Instagram</a>`)

  return (
    <Stack id={pageId} gap='20px' alignItems='start' color='white'>
      <Title>{title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: clickableDescription }} />
      <SocialsLink href={href} target='_blank' className='link'>
        {label}
      </SocialsLink>
    </Stack>
  )
}

export default React.memo(EventsFooter)

const Title = styled('div')(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '23px',
  letterSpacing: '0em'
}))

const Description = styled('div')(() => ({
  fontSize: '18px',
  opacity: 0.8,
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: 'white'
}))

const SocialsLink = styled(Link)(() => ({
  fontSize: '18px',
  opacity: 0.8
}))
