import fb from './../assets/images/fb.png'
import flickr from './../assets/images/flickr.png'
import ig from './../assets/images/ig.png'
import yt from './../assets/images/youtube.png'

export const ROUTES = {
  ROOT: '/',
  PROJECTS: '/projects'
} as const

export const FLICKR_URL: string = 'https://www.flickr.com/photos/spontanais/albums/with/72177720303925814'
export const FB_URL: string = 'https://www.facebook.com/letshike.co'
export const IG_URL: string = 'https://www.instagram.com/letshike.co'
export const YT_URL: string = 'https://www.youtube.com/@MovementSpontaneous'

export const PHONE: string = '+371 2592 5972'
export const MAIL: string = 'info@letshike.co'

export const SOCIALS = [
  {
    logo: fb,
    url: FB_URL,
    text: 'facebook',
    size: '14px'
  },
  {
    logo: ig,
    url: IG_URL,
    text: 'instagram',
    size: '15px'
  },
  {
    logo: yt,
    url: YT_URL,
    text: 'youtube',
    size: '21px'
  },
  {
    logo: flickr,
    url: FLICKR_URL,
    text: 'flickr',
    size: '20px'
  }
]

export const AlertType = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
} as const

export const Pages = {
  LANDING: 'LANDING',
  PHILOSOPHY: 'PHILOSOPHY',
  HIKERS: 'HIKERS',
  HIKES: 'HIKES',
  ADVENTURES: 'ADVENTURES',
  MORE_HIKES: 'MORE_HIKES',
  MAP: 'MAP',
  RENT: 'RENT',
  PARTNERS: 'PARTNERS'
} as const

export const HikerStyle = {
  HIKER_CARD_HEIGHT: 64,
  ROW_GAP: 32,
  VISIBLE_HIKER_ROWS: 6
} as const

export const Navigation = [
  { label: 'Events', page: Pages.HIKES },
  { label: 'Map', page: Pages.MAP },
  { label: 'Team', page: Pages.HIKERS },
  { label: 'Partners', page: Pages.PARTNERS },
  { label: 'Projects', page: ROUTES.PROJECTS }
] as const

export const EventType = {
  HIKE: 'Hike',
  KAYAK: 'Kayak'
}
