import { Stack } from '@mui/material'
import React from 'react'
import { SOCIALS } from '../common/constants'

const Socials = () => {
  return (
    <>
      {SOCIALS.map(({ logo, url, text, size }, index) => (
        <a target='_blank' rel='noreferrer' href={url} key={`${index}-social`}>
          <Stack alignItems='center'>
            <img src={logo} width={size} height={size} alt={text} />
          </Stack>
        </a>
      ))}
    </>
  )
}

export default React.memo(Socials)
