import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'

// A custom theme for this app
const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    // mode: 'dark',
    primary: {
      main: '#fff',
      light: '#3b3f42',
      dark: '#25292c',
      contrastText: '#000'
    },
    secondary: {
      main: '#000',
      light: '',
      dark: '',
      contrastText: '#fff'
    },
    error: {
      main: red.A400
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'none'
        }
      }
    }
  }
})

export default theme
