import ContainerWrapper from './ContainerWrapper'
import Description from './Description'
import React from 'react'
import useScreen from '../hooks/useScreen'
import { RootState } from '../redux'
import { styled } from '@mui/material/styles'
import { transientProps } from '../common/utils'
import { useSelector } from 'react-redux'

const Partners = ({ pageId, title, description }) => {
  const { isLaptop, isDesktop } = useScreen()
  const partners = useSelector((state: RootState) => state.api.partners)
  const columns = isDesktop ? 5 : isLaptop ? 4 : 3

  return (
    <ContainerWrapper color='black' title={title} id={pageId} sx={{}}>
      <Description>{description}</Description>
      <PatnersGrid
        sx={{
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`
        }}
      >
        {partners?.map((parner) => {
          const logoUrl = parner?.Logo?.[0]?.url
          const name = parner?.Name
          const href = parner?.URL

          if (!logoUrl || !name) return null
          return <Partner key={name} href={href} target='_blank' sx={{ backgroundImage: `url(${logoUrl})` }} />
        })}
      </PatnersGrid>
    </ContainerWrapper>
  )
}

export default React.memo(Partners)

const PatnersGrid = styled(
  'div',
  transientProps
)(() => ({
  width: '100%',
  display: 'grid',
  justifyContent: 'space-between',
  gap: '16px'
}))

const Partner = styled('a')(() => ({
  backgroundColor: 'black',
  borderRadius: '4px',
  width: '100%',
  height: '90px',
  cursor: 'pointer',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  transitionDuration: '0.3s',

  '&:hover': {
    transform: 'scale(1.1)'
  }
}))
