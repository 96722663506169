import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material/'
import { Button, Stack } from '@mui/material'
import React from 'react'

const ShowMoreButton = ({ isShowingMore, ...props }) => {
  return (
    <Button {...props} variant='text'>
      <Stack flexDirection='row' gap='5px' color='black' alignItems='center'>
        {isShowingMore ? <KeyboardArrowUp fontSize='small' /> : <KeyboardArrowDown fontSize='small' />}
        {isShowingMore ? 'Show less' : 'Show all'}
      </Stack>
    </Button>
  )
}

export default React.memo(ShowMoreButton)
