import { useState } from 'react'
import useSWR from 'swr'
import { parseValue } from '../common/utils'
import { combineParams, fetcher } from './requests'

const LS_KEY = 'PROJECTS'
export default function useProjects() {
  const cachedSections = localStorage?.getItem(LS_KEY)
  const defaultState = cachedSections ? parseValue(cachedSections, []) : []
  const params = combineParams({
    filterByFormula: `isPublic`
  })

  const key = `/Projects${params}`
  const [projectsData, setProjectsData] = useState<any[]>(defaultState)

  const projectsQuery = useSWR(key, fetcher, {
    onSuccess: (newData) => {
      try {
        const { records } = newData
        const parsed = records?.map(({ id, fields }) => ({
          id,
          ...fields,
          images: fields?.Images?.map(({ url }) => url),
          bottomImages: fields?.BottomImages?.map(({ url }) => url)
        }))
        if (parsed) {
          localStorage.setItem(LS_KEY, JSON.stringify(parsed))
        }
        setProjectsData(parsed)
      } catch (error) {}
    }
  })

  return { projectsData, ...projectsQuery }
}
