import ContainerWrapper from './ContainerWrapper'
import Description from './Description'
import Event from './Event'
import EventsFooter from './EventsFooter'
import React, { useEffect, useMemo, useState } from 'react'
import { FB_URL, Pages, YT_URL } from '../common/constants'
import { RootState } from '../redux'
import { Stack } from '@mui/material'
import { reachUrl } from '../common/utils'
import { useOnScreen } from '../hooks/useOnScreen'
import { useSelector } from 'react-redux'
import { ObjectValues } from '../common/interfaces'
import useScreen from '../hooks/useScreen'
import { styled } from '@mui/material/styles'
import useWebsite from '../swr/useWebsite'

const Events = ({ pageId, title, description }) => {
  const events = useSelector((state: RootState) => state.api.events)
  const { sectionsData } = useWebsite()

  const filteredEvents = useMemo(() => {
    if (events) {
      return events?.filter((event) => !!event?.Name && !!event?.['FB cover'])
    }

    return []
  }, [events])

  const [visibleEventCount, setVisibleEventCount] = useState<number>(10)
  const [lastElement, setLastElement] = useState(null)
  const { isVisible } = useOnScreen(lastElement, '0px')
  const { isMobile, isTablet } = useScreen()

  useEffect(() => {
    const element = document.getElementById(`event-${visibleEventCount - 1}`)
    setLastElement(element)
  }, [events, visibleEventCount])

  const loadMoreEvents = () => {
    const extraEvents = visibleEventCount + 10
    const newEventCount = extraEvents > events?.length ? events?.length : extraEvents

    setVisibleEventCount(newEventCount)
  }

  useEffect(() => {
    if (isVisible) {
      loadMoreEvents()
    }
  }, [isVisible])

  if (!events) {
    return null
  }

  const getEventFooter = ({ pageId, title, description }) => {
    const key = pageId

    switch (pageId as ObjectValues<typeof Pages>) {
      case Pages.ADVENTURES:
        return (
          <EventsFooter
            {...{
              key,
              pageId,
              title,
              description,
              link: {
                href: `${FB_URL}/events`,
                label: 'Upcoming events on Facebook'
              }
            }}
          />
        )
      case Pages.MORE_HIKES:
        return (
          <EventsFooter
            {...{
              key,
              pageId,
              title,
              description,
              link: {
                href: YT_URL,
                label: 'Visit our YouTube channel'
              }
            }}
          />
        )
    }
  }

  return (
    <ContainerWrapper color='black' title={title} id={pageId} sx={{ position: 'relative' }}>
      <Description>{description}</Description>
      <EventListWrapper>
        <EventList id='enable-scroll'>
          {filteredEvents
            .slice(0, visibleEventCount)
            .map(
              (
                { id, Name: eventName, Route: eventRoute, Date: eventDate, isFutureEvent, Flickr: flickrUrl, ...event },
                index
              ) => {
                const bgUrl = reachUrl(event?.['FB cover'])
                const isUpcoming: boolean = isFutureEvent === 1
                const fbEventUrl: string = event?.['Facebook event']
                const clickUrl = isUpcoming || !flickrUrl ? fbEventUrl : flickrUrl

                return (
                  <Event
                    id={`event-${index}`}
                    key={id}
                    clickUrl={clickUrl}
                    eventDate={eventDate}
                    eventRoute={eventRoute}
                    isUpcoming={isUpcoming}
                    bgUrl={bgUrl}
                    eventName={eventName}
                  />
                )
              }
            )}
        </EventList>
      </EventListWrapper>
      <Stack
        justifyContent='space-between'
        width='100%'
        flexDirection={isMobile || isTablet ? 'column' : 'row'}
        gap={isMobile || isTablet ? '4rem' : '6rem'}
      >
        {sectionsData.map(getEventFooter)}
      </Stack>
    </ContainerWrapper>
  )
}

export default React.memo(Events)

const EventListWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100vw',
  maxWidth: '100vw',
  left: 'calc(-50vw + 50%)',
  padding: '0px 16px'
}))

const EventList = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '16px',
  flexDirection: 'row',
  paddingBottom: '96px',
  marginBottom: '96px',
  overflowX: 'auto'
}))
