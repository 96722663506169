import { styled } from '@mui/material/styles'
import React from 'react'

const Video = ({ url, ...props }) => {
  return (
    <VideoBox width='100%' height='auto' autoPlay loop muted {...props} key={url}>
      <source src={url} type='video/mp4' />
    </VideoBox>
  )
}

export default Video

const VideoBox = styled('video')(() => ({
  objectFit: 'cover',
  maxHeight: '100vh',
  minHeight: 600,
  height: '100%',
  transitionDuration: '1s'
}))
