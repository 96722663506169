import MS_LOGO from './../assets/images/ms_logo_white.jpg'
import React from 'react'
import { RootState } from '../redux'
import { styled } from '@mui/material/styles'
import { transientProps } from '../common/utils'
import { useSelector } from 'react-redux'
import { HikerStyle } from '../common/constants'
import useScreen from '../hooks/useScreen'

export interface IHiker {
  id: string
  name: string
  photo: string
  eventCount: string
}

const HikersList = () => {
  const hikersData = useSelector((state: RootState) => state.api.hikers)
  const { isMobile, isTablet } = useScreen()
  const isSmallScreen = isMobile || isTablet

  return hikersData
    .sort((a, b) => b.eventCount - a.eventCount)
    .filter(({ photo }) => !!photo)
    .slice(0, 96)
    .map(({ photo, name, id, eventCount }: IHiker) => {
      return (
        <Hiker key={id}>
          <Photo $isSmallScreen={isSmallScreen} alt='person' src={photo || MS_LOGO} />
          <div>
            <Name $isSmallScreen={isSmallScreen}>{name}</Name>
            <HikeCount $isSmallScreen={isSmallScreen}>{eventCount} events</HikeCount>
          </div>
        </Hiker>
      )
    })
}

export default React.memo(HikersList)

const Hiker = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: HikerStyle.HIKER_CARD_HEIGHT
}))

const Photo = styled(
  'img',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  width: $isSmallScreen ? '48px' : '64px',
  height: $isSmallScreen ? '48px' : '64px',
  borderRadius: '50px'
}))

const Name = styled(
  'div',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  fontWeight: 500,
  fontSize: $isSmallScreen ? '14px' : '18px',
  lineHeight: '23px'
}))

const HikeCount = styled(
  'div',
  transientProps
)(({ $isSmallScreen }: { $isSmallScreen: boolean }) => ({
  fontWeight: 400,
  fontSize: $isSmallScreen ? '12px' : '16px',
  lineHeight: '24px',
  opacity: 0.6
}))
