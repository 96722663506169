import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ROUTES } from './common/constants'
import Landing from './components/Landing'
import Projects from './components/Projects'
import { fetchEvents, fetchHikers, fetchPartners } from './redux/apiActionts'

const App = () => {
  const dispatch = useDispatch<any>()

  useEffect(() => {
    dispatch(fetchHikers())
    dispatch(fetchEvents())
    dispatch(fetchPartners())
  }, [])

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.ROOT} element={<Landing />} />
        <Route path={ROUTES.PROJECTS} element={<Projects />} />
      </Routes>
    </Router>
  )
}

export default React.memo(App)
