import { Stack } from '@mui/material'
import React from 'react'
import { Pages } from '../common/constants'
import { ObjectValues } from '../common/interfaces'
import useWebsite from '../swr/useWebsite'
import Events from './Events'
import Footer from './Footer'
import Header from './Header'
import Hikers from './Hikers'
import LandingVideo from './LandingVideo'
import Map from './Map'
import Partners from './Partners'
import Phylosophy from './Phylosophy'

const Landing = () => {
  const { sectionsData } = useWebsite()

  const getPage = ({ pageId, title, description, attachment }) => {
    const key = pageId

    switch (pageId as ObjectValues<typeof Pages>) {
      case Pages.LANDING:
        return <LandingVideo {...{ pageId, title, description, attachment, key }} />
      case Pages.HIKERS:
        return <Hikers {...{ pageId, title, description, key }} />
      case Pages.PHILOSOPHY:
        return <Phylosophy {...{ pageId, title, description, key }} />
      case Pages.PARTNERS:
        return <Partners {...{ pageId, title, description, key }} />
      case Pages.HIKES:
        return <Events {...{ pageId, title, description, key }} />
      case Pages.MAP:
        return <Map {...{ pageId, attachment, key }} />
    }
  }

  if (!sectionsData || sectionsData?.length === 0) {
    return
  }

  return (
    <Stack>
      <Header />
      {sectionsData?.map(getPage)}
      <Footer />
    </Stack>
  )
}

export default React.memo(Landing)
