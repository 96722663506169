export const transientProps = {
  shouldForwardProp: (propName: any) => !propName.startsWith('$')
}

export const scrollTo = (id) => {
  const elementId = document?.getElementById(id)
  elementId?.scrollIntoView({ behavior: 'smooth' })
}

export const getObjectValue = (object, valuePath: string) => {
  const value = valuePath?.split('.')?.reduce((o, k) => (o || {})[k], object)
  const isNullOrUndefined = value === null || value === undefined
  return isNullOrUndefined ? '' : value
}

export const getObjectId = (object, valuePath) => {
  return object?.[valuePath] || getObjectValue(object, valuePath)
}

export const reachUrl = (attachment) => attachment?.[0]?.url

export const swap = (arr: any[], from: number, to: number) => {
  if (arr.length === 1) return arr
  const _arr = [...arr]
  _arr.splice(to, 1, _arr.splice(from, 1, _arr[to])[0])
  return _arr
}

export const parseValue = (value: string, fallback?: any) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    if (fallback) return fallback
    return null
  }
}
