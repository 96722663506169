import { AlertType } from '../common/constants'
import { IAlert } from '../components/Unused/Alert'

export const AppActions = {
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_ALERT: 'SET_ALERT',
  SET_USER_LOCATION: 'SET_USER_LOCATION'
} as const

export function logIn() {
  return {
    type: AppActions.SET_LOGIN_STATUS,
    payload: true
  }
}

export function logOut() {
  return {
    type: AppActions.SET_LOGIN_STATUS,
    payload: false
  }
}

interface IUser {}
export function setUserData(payload: IUser) {
  return {
    type: AppActions.SET_USER_DATA,
    payload
  }
}

export const showAlert = (payload: IAlert) => {
  return {
    type: AppActions.SET_ALERT,
    payload
  }
}

export const hideAlert = () => {
  return {
    type: AppActions.SET_ALERT,
    payload: null
  }
}

export const handleError = (errorResponse: any) => {
  return async (dispatch) => {
    const errorCode = await errorResponse?.response?.data
    const message = 'Kļūda sistēmas darbībā'
    dispatch(showAlert({ type: AlertType.ERROR, message }))
  }
}

export const setUserLocation = (payload) => {
  return {
    type: AppActions.SET_USER_LOCATION,
    payload
  }
}
