import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGeoParser = (geojson) => {
  const [filteredGeoJson, setFilteredGeoJson] = useState()

  useEffect(() => {
    const fetchGeoJson = async () => {
      try {
        const url = geojson?.[0]?.url
        const { data } = await axios.get(url)

        const filteredData = data?.features?.reduce((value, currValue) => {
          const type = String(currValue?.properties?.Type)
          const newData = {
            ...value,
            [type]: [...(value?.[type] || []), currValue]
          }
          return newData
        }, {})

        setFilteredGeoJson(filteredData)
      } catch (error) {}
    }

    if (geojson) {
      fetchGeoJson()
    }
  }, [geojson])

  return { filteredGeoJson }
}

export default useGeoParser
