import Airtable from 'airtable'
import { handleError } from './appActions'

const AirtableApiKey: string = process.env.REACT_APP_AIRTABLE_API ?? ''
const AirtableBaseKey: string = process.env.REACT_APP_BASE ?? ''
export const base = new Airtable({ apiKey: AirtableApiKey }).base(AirtableBaseKey)

export const EApiActions = {
  SET_HIKERS: 'SET_HIKERS',
  SET_SECTIONS: 'SET_SECTIONS',
  SET_EVENTS: 'SET_EVENTS',
  SET_PARTNERS: 'SET_PARTNERS'
} as const

export function fetchHikers() {
  return async (dispatch, getState) => {
    const fields = ['Name', 'Sum events', 'Photo']
    const PeopleBase = base('People')
    const view = 'Grid view'
    const maxRecords = 1000
    const hikers = []

    try {
      await PeopleBase.select({ maxRecords, view, fields }).eachPage(function page(records, fetchNextPage) {
        records.forEach((record: any) => {
          hikers.push({
            id: record.id,
            name: record.fields?.Name,
            photo: record.fields?.Photo?.[0]?.url,
            eventCount: record.fields?.['Sum events']
          })
        })
        fetchNextPage()
      })
      dispatch(setHikers(hikers))
      return hikers
    } catch (error) {
      return dispatch(handleError(error))
    }
  }
}

export const setHikers = (payload) => {
  return {
    type: EApiActions.SET_HIKERS,
    payload
  }
}

export const fetchEvents = () => {
  return async (dispatch, getState) => {
    const fields = [
      'Lenght (km)',
      'Participants (P)',
      'FB cover',
      'Date',
      'Name',
      'Route',
      'isFutureEvent',
      'Flickr',
      'Facebook event',
      'hideOnWeb'
    ]
    const EventsBase = base('Events')
    const view = 'Chronological'
    const maxRecords = 1000
    const events = []

    const currentEvents = getState().api.events
    if (currentEvents) {
      return currentEvents
    }

    try {
      await EventsBase.select({ maxRecords, view, fields }).eachPage(function page(records, fetchNextPage) {
        records.forEach(({ id, fields }: any) => {
          if (!fields?.hideOnWeb) {
            events.push({ id, ...fields })
          }
        })
        fetchNextPage()
      })

      const newestEvents = events.reverse()
      dispatch(setEvents(newestEvents))
      return newestEvents
    } catch (error) {
      return dispatch(handleError(error))
    }
  }
}

export const setEvents = (payload) => {
  return {
    type: EApiActions.SET_EVENTS,
    payload
  }
}

export const fetchPartners = () => {
  return async (dispatch, getState) => {
    const fields = ['Name', 'Relationship Statuss', 'URL', 'Logo', 'Show on website']
    const PartnersBase = base('Partners')
    const view = 'Grid view'
    const maxRecords = 1000
    const partners = []

    try {
      await PartnersBase.select({ maxRecords, view, fields }).eachPage(function page(records, fetchNextPage) {
        records.forEach(({ id, fields }: any) => {
          const isShowing: boolean = fields['Show on website']
          isShowing && partners.push({ id, ...fields })
        })
        fetchNextPage()
      })

      dispatch(setPartners(partners))
      return partners
    } catch (error) {
      return dispatch(handleError(error))
    }
  }
}

export const setPartners = (payload) => {
  return {
    type: EApiActions.SET_PARTNERS,
    payload
  }
}
