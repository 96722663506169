import { styled } from '@mui/material/styles'
import React from 'react'
import useScreen from '../hooks/useScreen'

const Description = ({ children, ...rest }) => {
  const { isMobile, isTablet } = useScreen()

  const width = isMobile || isTablet ? '100%' : '576px'
  const marginBottom = isMobile || isTablet ? '64px' : '96px'

  return (
    <Text {...rest} sx={{ width, marginBottom }}>
      {children}
    </Text>
  )
}

export default React.memo(Description)

const Text = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '30px',
  maxWidth: '100%',
  padding: '16px 0px',
  opacity: 0.8
}))
