import useSWR from 'swr'
import { combineParams, fetcher } from './requests'
import { parseValue } from '../common/utils'
import { useState } from 'react'

const LS_KEY = 'WEBSITE'
export default function useWebsite() {
  const cachedSections = localStorage?.getItem(LS_KEY)
  const defaultState = cachedSections ? parseValue(cachedSections, []) : []
  const params = combineParams({
    filterByFormula: `isPublic`
  })

  const key = `/Website${params}`
  const [sectionsData, setSectionsData] = useState<any[]>(defaultState)
  const websiteQuerry = useSWR(key, fetcher, {
    onSuccess: (newData) => {
      try {
        const { records } = newData
        const parsed = records?.map(({ id, fields }) => ({ id, ...fields }))
        const sorted = parsed?.sort((a, b) => a.order - b.order)
        if (sorted) {
          localStorage.setItem(LS_KEY, JSON.stringify(sorted))
        }
        setSectionsData(sorted)
      } catch (error) {}
    }
  })

  return { sectionsData, ...websiteQuerry }
}
