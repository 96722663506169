import { Accordion, AccordionDetails, AccordionSummary, Stack, styled } from '@mui/material'
import useProjects from '../swr/useProjects'
import ContainerWrapper from './ContainerWrapper'
import Footer from './Footer'
import Header from './Header'
import Markdown from 'react-markdown'

type Project = {
  id: string
  'Project Acronym': string
  'Project Title Full': string
  'Project objective': string
  'Project results': string
  'Project budget information': string
  'Project implementation': string
  isPublic: boolean
  images: string[]
  bottomImages: string[]
}

export default function Projects() {
  const { projectsData } = useProjects()

  return (
    <>
      <Header />
      <ContainerWrapper
        color='black'
        title=''
        id='Projects'
        sx={{ position: 'relative', minHeight: 'calc(100vh - 200px)' }}
      >
        <Stack
          direction='column'
          alignItems='center'
          height='100%'
          id='container'
          width='100%'
          style={{ backgroundColor: 'black', color: 'white' }}
        >
          <Title style={{ fontSize: 36 }}>Projects</Title>
          {projectsData?.map(
            (
              {
                id,
                'Project Acronym': projectAcronym,
                'Project Title Full': projectTitleFull,
                'Project objective': projectObjective,
                'Project results': projectResults,
                'Project budget information': projectBudgetInformation,
                'Project implementation': projectImplementation,
                isPublic,
                images,
                bottomImages
              }: Project,
              index
            ) => {
              return (
                <Accordion
                  key={index}
                  defaultExpanded
                  expanded={true}
                  style={{ backgroundColor: 'unset', color: 'white', maxWidth: 900 }}
                >
                  <AccordionSummary expandIcon={null}>
                    <Title style={{ fontSize: 22 }}>{projectTitleFull}</Title>
                  </AccordionSummary>
                  {images?.map((src, index) => (
                    <img alt={`project-image`} key={index} width='100%' src={src} />
                  ))}
                  <AccordionDetails>
                    <Stack gap='1rem' key={index}>
                      <Title>Project objective</Title>
                      <MarkdownText>{projectObjective}</MarkdownText>
                      <Title>Project results</Title>
                      <MarkdownText>{projectResults}</MarkdownText>
                      <Title>Project budget information</Title>
                      <MarkdownText>{projectBudgetInformation}</MarkdownText>
                      <Title>Project implementation</Title>
                      <MarkdownText>{projectImplementation}</MarkdownText>
                      {bottomImages?.map((src, index) => (
                        <img alt={`project-image`} key={index} width='100%' src={src} />
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )
            }
          )}
        </Stack>
      </ContainerWrapper>
      <Footer />
    </>
  )
}

const MarkdownText = ({ children }: { children: string }) => {
  return (
    <Text>
      <Markdown>{children}</Markdown>
    </Text>
  )
}

const Title = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '30px',
  fontFamily: 'Work Sans'
}))

const Text = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  maxWidth: '100%',
  opacity: 0.8,
  fontFamily: 'Work Sans',
  textAlign: 'justify',

  '& p': {
    margin: 0,
    padding: 0
  },

  '& ol': {
    margin: 0,
    paddingInlineStart: 20
  },

  '& a': {
    textDecoration: 'none',
    borderBottom: '1px solid',
    '&:hover': {
      borderBottom: '1px solid #00000000'
    }
  }
}))
