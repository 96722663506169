const URL = `https://api.airtable.com/v0/appWPfi8rc24FiRPv`
export const fetcher = (apiPath) =>
  fetch(`${URL}${apiPath}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API}`
    }
  }).then((res) => res.json())

export const combineParams = (obj: Record<any, any>) => {
  if (!obj) return ''

  return Object.keys(obj).reduce((prev, current) => {
    const value = encodeURIComponent(obj?.[current])
    if (!value) return prev
    const param = `${current}=${String(value)?.replaceAll(' ', '')}`
    return prev ? `${prev}&${param}` : `?${param}`
  }, '')
}
