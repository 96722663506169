import { EApiActions } from './apiActionts'

const INITIAL_STATE = {
  hikers: null,
  sections: null,
  events: null,
  partners: null
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case EApiActions.SET_HIKERS:
      return {
        ...state,
        hikers: action?.payload
      }
    case EApiActions.SET_SECTIONS:
      return {
        ...state,
        sections: action?.payload
      }
    case EApiActions.SET_EVENTS:
      return {
        ...state,
        events: action?.payload
      }
    case EApiActions.SET_PARTNERS:
      return {
        ...state,
        partners: action?.payload
      }
    default:
      return state
  }
}

export default reducer
