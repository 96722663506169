import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import './app.css'
import { store } from './redux/store'
import theme from './theme'
import React from 'react'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </>
)
