import { combineReducers } from "redux"
import appReducer from "./appReducer"
import apiReducer from "./apiReducer"
import { store } from "./store"

const reducers = combineReducers({
	app: appReducer,
	api: apiReducer,
})

export default reducers

export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch | any
