import { useMediaQuery } from 'react-responsive'

export default function useScreen() {
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1024 })
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 768 })
  const isMobile = useMediaQuery({ maxWidth: 640 })

  return {
    isDesktop,
    isLaptop,
    isTablet,
    isMobile
  }
}
