import { useEffect, useState } from 'react'

export const useOnScreen = (DOMElement, rootMargin: string) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), {
    rootMargin
  })

  useEffect(() => {
    try {
      observer?.observe(DOMElement)
    } catch (error) {}
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [DOMElement])

  return { isVisible }
}
