import { Menu as MenuIcon } from '@mui/icons-material'
import { Drawer, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Navigation } from '../common/constants'
import { scrollTo, transientProps } from '../common/utils'
import useScreen from '../hooks/useScreen'
import MS_LOGO_SMALL from './../assets/images/ms_logo_black.jpg'
import MS_LOGO_BIG from './../assets/images/ms_white_full.png'
import ContainerWrapper from './ContainerWrapper'
import Socials from './Socials'

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const { isMobile, isTablet } = useScreen()
  const isSmallScreen: boolean = isMobile || isTablet
  const navigate = useNavigate()
  const location = useLocation()

  const getNavigation = () =>
    Navigation.map(({ label, page }) => (
      <NavButton
        sx={{
          fontSize: !isSmallScreen ? '16px' : '24px',
          textDecoration: location.pathname === page ? 'none' : ''
        }}
        key={label}
        onClick={() => {
          setDrawerOpen(false)
          const isLandingView = location.pathname === '/'
          if (isLandingView) {
            if (page.startsWith('/')) {
              return navigate(page)
            }
            return scrollTo(page)
          }
          navigate('/')
          setTimeout(() => scrollTo(page))
        }}
      >
        {label}
      </NavButton>
    ))

  const getDrawer = () => (
    <Drawer anchor='right' open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
      <DrawerBox>
        <Stack flexDirection='row' alignItems='center' justifyContent='flex-end' padding='10px' gap='10px'>
          <Socials />
        </Stack>
        <Stack gap='10px'>{getNavigation()}</Stack>
      </DrawerBox>
    </Drawer>
  )

  return (
    <ContainerWrapper color='black' id='header' sx={{ padding: isSmallScreen ? '16px 32px' : '48px 96px' }}>
      <Container>
        <Stack flexDirection='row' alignItems='center'>
          {getDrawer()}
          <img
            alt='logo'
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
            src={isSmallScreen ? MS_LOGO_SMALL : MS_LOGO_BIG}
            width='auto'
            height='32px'
          />
        </Stack>
        <Stack alignItems='center' justifyContent='center'></Stack>
        <Stack flexDirection='row' alignItems='center' justifyContent='flex-end' gap='14px'>
          {isSmallScreen ? <MenuIcon onClick={() => setDrawerOpen(true)} /> : getNavigation()}
        </Stack>
      </Container>
    </ContainerWrapper>
  )
}

export default React.memo(Header)

const NavButton = styled(
  'label',
  transientProps
)(() => ({
  textTransform: 'none',
  fontWeight: 400,
  cursor: 'pointer',
  margin: '0px 10px',
  textDecoration: 'underline',
  fontSize: 16,
  opacity: 0.8,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    textDecoration: 'none'
  }
}))

const Container = styled('div')(() => ({
  width: '100%',
  color: 'white',
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(3, 1fr)',
  alignItems: 'center'
}))

const DrawerBox = styled('div')(() => ({
  minWidth: '300px',
  maxWidth: '90vw',
  paddingTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: '#171717',
  height: '100%',
  padding: '20px 10px 50px 10px',
  color: 'white',
  justifyContent: 'space-between'
}))
