import { ObjectValues } from '../common/interfaces'
import { AppActions } from './appActions'

const INITIAL_STATE = {
  isLoggedIn: sessionStorage.getItem('isLoggedIn') === 'true',
  userData: null,
  alert: null,
  userLocation: null
}

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type as ObjectValues<typeof AppActions>) {
    case AppActions.SET_LOGIN_STATUS:
      sessionStorage.setItem('isLoggedIn', JSON.stringify(payload))
      return {
        ...state,
        isLoggedIn: payload
      }

    case AppActions.SET_USER_DATA:
      return {
        ...state,
        userData: payload
      }
    case AppActions.SET_ALERT:
      return {
        ...state,
        alert: payload
      }
    case AppActions.SET_USER_LOCATION:
      return {
        ...state,
        userLocation: payload
      }
    default:
      return state
  }
}

export default reducer
