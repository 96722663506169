const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const

export const convertYYMMDD = (date: string) => {
  try {
    const [YY, MM, DD] = date.split('-')
    const parsedMM = Number(MM)
    return `${DD} ${MONTHS[parsedMM - 1]} ${YY}`
  } catch (error) {
    return date
  }
}
