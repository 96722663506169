import { EventType } from './constants'
import { ObjectValues } from './interfaces'

export const getTypeColor = (type: ObjectValues<typeof EventType>) => {
  switch (type) {
    case EventType.HIKE:
      return '#e55200'
    case EventType.KAYAK:
      return '#c2175b'
    default:
      return '#3b84df'
  }
}

export const isTouchEvent = (e) => e.originalEvent && 'touches' in e.originalEvent
export const isTwoFingerTouch = (e) => e.originalEvent.touches.length >= 2
