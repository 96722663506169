import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'


const useMapbox = () => {
  return {mapboxgl}
}

export default useMapbox